<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="540px" transition="dialog-transition">
      <v-card>
        <v-form @submit.prevent="create">
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold" v-text="$t('project.createProjectDialog.newProject')" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="primary" @click="$emit('closeDialog')">
                  <v-icon v-text="'close'" />
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.name"
              :error-messages="getFieldErrors('name')" :label="$t('common.name')" outlined dense class="mb-1" @blur="$v.form.name.$touch()"
            />
            <v-select
              v-model="form.type"
              :error-messages="getFieldErrors('type')" :label="$t('common.type')" :items="typeItems" outlined dense class="mb-1" @blur="$v.form.type.$touch()"
            />
            <v-autocomplete
              v-model="form.timezone"
              :error-messages="getFieldErrors('timezone')" :label="$t('common.timeZone')" :items="timezoneItems"
              outlined dense class="mb-1" @blur="$v.form.timezone.$touch()"
            />
            <v-layout v-for="(item, i) in allCheckboxes" :key="i" row wrap>
              <v-checkbox v-model="form[item.model][item.name]" dense class="px-3">
                <template #label>
                  <div>
                    {{ item.text }}
                    <global-tooltip
                      v-if="item.tooltip"
                      :text="item.tooltip"
                    />
                  </div>
                </template>
              </v-checkbox>
            </v-layout>
          </v-card-text>
          <v-card-actions class="px-6 pb-5 pt-0 d-flex justify-center">
            <v-btn color="primary" depressed type="submit" v-text="$t('project.createProjectDialog.createProject')" />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import timezonesEnum from '@/enums/timezonesEnum'

export default {
  name: 'CreateGuildDialog',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, required: true },
  },
  data: () => ({
    form: {
      type: 'guild',
      name: null,
      features: {
        price: false,
        terms: false,
        form: true,
        enabledChat: true,
        doNotSendNotificationOnPublish: true,
      },
    },
  }),
  computed: {
    typeItems() {
      return [
        { text: this.$t('guild.type.guild'), value: 'guild' },
      ]
    },
    allCheckboxes() {
      return [
        {
          model: 'features',
          name: 'price',
          text: this.$t('project.createProjectDialog.checkbox.price'),
        },
        {
          model: 'features',
          name: 'terms',
          text: this.isCompetition ? this.$t('project.createProjectDialog.checkbox.termsCompetition') : this.$t('project.createProjectDialog.checkbox.terms'),
          tooltip: this.$t('project.createProjectDialog.tooltip.terms'),
        },
      ]
    },
    timezoneItems: () => timezonesEnum.map(v => ({ text: v, value: v })),
  },
  validations: { form: { type: { required }, name: { required }, timezone: { required } } },
  methods: {
    async create() {
      const { organizationId, clusterId } = this
      if (!this.isFormValid()) return
      const data = {
        ...this.form,
        features: {
          ...this.form.features,
          form: true,
        },
        subscriberApproval: { required: true },
      }
      await this.runAsync(() => this.$store.dispatch('guild/create', { organizationId, clusterId, data }))
      this.$emit('closeDialog')
    },
  },
}
</script>
